import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client"
import { func } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import {
  UNIVERSITY_DETAIL_DATA,
  UNIVERSITY_DETAIL_URL,
  UNIVERSITY_PROGRAMME_DETAIL,
  USER_WISHLIST_BOOKMARK_QUERY,
  UPDATE_WISHLIST_UNIVERSITIES_DATA,
  UPDATE_WISHLIST_UNIVERSITIES_USER,
  GET_ALL_WISHLISTED_UNIVERSITIES_USER,
  UNIVERSITY_CAMPUSES_DATA,
} from "../components/graphql"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WriteReview from "../components/writeReview"
import { Link } from "gatsby"
import $ from "jquery"
import Login from "../components/login"

import Loadable from "@loadable/component"
import BounceIndicator from "react-activity/lib/Bounce"

import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import ReactStars from "react-rating-stars-component"
import ReactReadMoreReadLess from "react-read-more-read-less"
import ReactMarkdown from "react-markdown"
import parse from "html-react-parser"
import GoogleMap from "../components/google-map"

const Universitydetail = university_detail_content => {
  const client = useApolloClient()
  const [username, setUsername] = useState(undefined)
  const [userID, setUserID] = useState(null)
  const [viewAll, setViewAll] = useState(false)
  const [Inprogramme, setProgramme] = useState(false)
  const [showAdmission, setShowAdmission] = useState(false)
  const [showReview, setShowReview] = useState(false)
  const [articleList, setArticleList] = useState([])
  const userWishlistBookmarkData = useQuery(USER_WISHLIST_BOOKMARK_QUERY, {
    variables: { id: userID },
    pollInterval: 7000,
  })
  const [courseId, setCourseId] = useState("")
  const [active, setActive] = useState({ id: "#section11" })
  const [aboutPage, setAboutPage] = useState(false)
  const [Net, setNet] = useState(1000)
  const [programmeDescription, setProgrammeDescription] = useState("")
  const [writeReviewOpen, setWriteReviewOpen] = useState(false)
  const [updateUserWishlist] = useMutation(UPDATE_WISHLIST_UNIVERSITIES_USER)
  const [updateUniversityWishlist] = useMutation(
    UPDATE_WISHLIST_UNIVERSITIES_DATA
  )
  const [wishlisted, setWishlisted] = useState({})
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const OwlCarousel = Loadable(() => import("react-owl-carousel"))
  const [loader, setLoader] = useState({ id: false })
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  React.useEffect(() => {
    console.log("useEffect called")
    setUsername(localStorage.getItem("userName"))
    setUserID(localStorage.getItem("userId"))
    wishlistRender()
    // Humburger menu
    $("#humburger-menu").click(function () {
      $("#bar").toggleClass("fa-times")
      $(".menu-items ").toggle("slow")
    })
  },[]);

  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});
  
  var locationRef = university_detail_content.location.href
  if (locationRef) {
    if (locationRef.slice(-1) === "/") {
      locationRef = locationRef.slice(0, -1)
    }
  }
  var universityId = ""
  var lastContent = undefined
  var strippedContent = undefined

  const { strapiUniversities, allStrapiHomePage } =
    university_detail_content.data
  console.log(
    "-----------------------------strapiUniversities.universityName",
    strapiUniversities !== null
      ? strapiUniversities.universityName
      : "no data" + strapiUniversities
  )
  const seoTitle =
    strapiUniversities !== null ? strapiUniversities.universityName : ""
  const seoMetaDescription =
    strapiUniversities !== null ? strapiUniversities.metaDescription : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""

  if (locationRef) {
    lastContent = locationRef.substring(locationRef.lastIndexOf("/") + 1)

    strippedContent = lastContent.substring(
      0,
      lastContent.indexOf("#") + "#".length - 1
    )

    if (strippedContent === "") {
      strippedContent = lastContent
    }
  }

  const slug = strippedContent
  console.log("strippedContent - topic", slug)
  var universityId = "0"
  var wishlistCount1 = 0

  const slugContent = useQuery(UNIVERSITY_DETAIL_URL, {
    variables: { url: slug },
  })
  if(slugContent){
    if (slugContent.data) {
      if (slugContent.data.universities.length !== 0) {
        universityId = slugContent.data.universities[0].id
      }
    }
  }
  if (!slug || slug === "null" || slug === "undefined") {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.replace("/universities")
    }
  }

  const DateFunc = date2 => {
    if (date2) {
      const subdate = date2.split("-")
      let date1 = new Date(
        parseInt(subdate[0]),
        parseInt(subdate[1]) - 1,
        parseInt(subdate[2])
      ) // 2020-06-21
      let longMonth = date1.toLocaleString("en-us", { month: "long" })
      let value =
        longMonth + " " + parseInt(subdate[2]) + ", " + parseInt(subdate[0])
      return value
    } else {
      return
    }
  }

  const handleClick = e => {
    // console.log(e.target.id, "e.target.hash====99999999999999999999999")

    setActive({ id: e.target.id })
    setAboutPage(true)
    setViewAll(false)
    //  scrollUPFunc(e.target.id)
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.scrollTo({
        top: $(e.target.id)?.offset()?.top - 120,
        behavior: "smooth",
        /* you can also use 'auto' behaviour
       in place of 'smooth' */
      })
    }
    //  setScrollUpId()
  }

  const detailContent = useQuery(UNIVERSITY_DETAIL_DATA, {
    variables: { id: universityId, limit: 2 },
  })

  const programmeDetailContent = useQuery(UNIVERSITY_PROGRAMME_DETAIL, {
    variables: { id: universityId, limit: 0 },
  })
  const universityCampusesContent = useQuery(UNIVERSITY_CAMPUSES_DATA, {
    variables: { id: universityId },
  })

  {
    universityCampusesContent.data &&
      console.log(
        universityCampusesContent.data.university.aboutPage,
        "universityCampusesContent.data//////////////"
      )
  }

  const scrollToTop = () => {
    $("html, body").animate({ scrollTop: 500 }, 250)
    // window.scrollTo({

    //   top: $("#main-div").offset().top-100,
    //   behavior: "smooth",
    //   /* you can also use 'auto' behaviour
    //      in place of 'smooth' */
    // })

    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
    // console.log($("#main-div").offset().top+500,"/////////////////////////////////////")
  }

  const Numbervalue = e => {
    if (e) {
      var indexNumber = parseInt(e)
      console.log(indexNumber, e, Net)

      var value = indexNumber.toLocaleString("en-IN")
      console.log(value)
      return value
    } else {
      var value = 0
      return value
    }
  }

  const AboutPageFun = e => {
    e.preventDefault()
    setActive({ id: "#section11" })

    setViewAll(false)
    setAboutPage(true)
    setShowAdmission(false)
    setProgramme(false)
    setShowReview(false)
    scrollToTop()
  }
  const ViewAllProgrammes = e => {
    e.preventDefault()
    setActive({ id: "#section21" })
    setViewAll(true)
    setAboutPage(false)
    setShowAdmission(false)
    setProgramme(false)
    setShowReview(false)
    // AllProgrammes.current.click();
    scrollToTop()
  }
  // const HandleProgramme = (e, id) => {
  //   e.preventDefault()
  //   setActive({ id: "#section11" })
  //   setViewAll(true)
  //   setAboutPage(false)
  //   setShowAdmission(false)
  //   setShowReview(false)

  //   setProgrammeDescription(id)
  //   setProgramme(true)
  //   scrollToTop()
  // }
  const ViewAdmission = e => {
    e.preventDefault()
    setActive({ id: "#section11" })
    setViewAll(false)
    setProgramme(false)
    setAboutPage(false)
    setShowAdmission(true)
    setShowReview(false)
    scrollToTop()
  }

  const ReviewDetails = e => {
    e.preventDefault()
    console.log("Review Details")

    setActive({ id: "#section11" })
    setViewAll(false)
    setProgramme(false)
    setAboutPage(false)
    setShowAdmission(false)
    setShowReview(true)
    scrollToTop()
  }
  const wishlistRender = () => {
    userWishlistBookmarkData.data &&
      userWishlistBookmarkData.data.user &&
      userWishlistBookmarkData.data.user.wishlisted_universities.length &&
      userWishlistBookmarkData.data.user.wishlisted_universities.map(c => {
        if (c.id === detailContent.data.university.id) {
          wishlisted[detailContent.data.university.id] = "true"
          // setLoader(="true";
          // setLoader(prevState=>({...prevState,[content.id]:true}))
        }
      })
  }

  const UnWishlistTrigger = content => {
    // console.log("wishlisted[universityId]",wishlisted[content.id])
    setLoader(prevState => ({ ...prevState, [content.id]: true }))
    client
      .query({
        query: GET_ALL_WISHLISTED_UNIVERSITIES_USER,
        variables: { id: userID },
      })
      .then(res => {
        if (res.data.user) {
          var articleList = []

          if (res.data.user.wishlisted_universities.length > 0) {
            res.data.user.wishlisted_universities.map(record => {
              if (content.id !== record.id) {
                articleList.push(record.id)
              }
            })
          }

          updateUserWishlist({
            variables: {
              id: userID,
              wishlistID: articleList,
            },
          }).then(response => {
            var count = 0
            if (content.wishlistCount) {
              count = content.wishlistCount
            }
            count = count - 1
            updateUniversityWishlist({
              variables: {
                id: content.id,
                wishlistCount: count,
              },
            })
              .then(response => {
                console.log("response", response)
              })
              .finally(e => {
                wishlisted[content.id] = false
                setLoader(prevState => ({
                  ...prevState,
                  [content.id]: false,
                }))
              })
          })
        }
      })
  }

  const WishlistTrigger = (content, e) => {
    universityId = content.id //this is for blog id
    setLoader(prevState => ({ ...prevState, [content.id]: true }))
    // console.log("wishlisted[universityId]",wishlisted[universityId])
    //   if (localStorage.getItem("userId")) {
    //     console.log("logouttttttttttttttttttttttttt")
    //   e.preventDefault()
    //   setOpen(true)
    //   setLogin(true)

    //   localStorage.setItem("redirect", true)
    //   return false
    // }
    console.log("wishlist clickdfddddddddddddddddddddddddddd")

    // console.log(loader, "loader ---------------")
    if (localStorage.getItem("userId")) {
      client
        .query({
          query: UNIVERSITY_DETAIL_DATA,
          variables: { id: universityId },
        })
        .then(res => {
          wishlistCount1 = res.data.university.wishlistCount
          // console.log(wishlistCount1,"LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLl")
        })
        .catch(err => console.log(err))
    }
    if (localStorage.getItem("userId")) {
      client
        .query({
          query: GET_ALL_WISHLISTED_UNIVERSITIES_USER,
          variables: { id: userID },
        })
        .then(res => {
          console.log(articleList.length)
          console.log("articleList first=====", articleList)
          if (res.data.user) {
            if (res.data.user.wishlisted_universities.length > 0) {
              res.data.user.wishlisted_universities.map(record => {
                articleList.push(record.id)
              })
            }
            if (!res.data.user.wishlisted_universities.includes(universityId)) {
              // setArticleList(articleList => [universityId, ...articleList])
              articleList.push(universityId)
            }
            updateUserWishlist({
              variables: {
                id: userID,
                wishlistID: articleList,
              },
            }).then(response => {
              console.log(response)
              var count = 0
              if (wishlistCount1) {
                count = wishlistCount1
              }
              count = count + 1
              updateUniversityWishlist({
                variables: {
                  id: universityId,
                  wishlistCount: count,
                },
              })
                .then(response => {
                  console.log("response", response)
                  console.log("articleList last=====", articleList)
                })
                .finally(e => {
                  wishlisted[universityId] = true
                  setLoader(prevState => ({
                    ...prevState,
                    [content.id]: false,
                  }))
                  console.log(loader, "loader -------------///////////--")
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","university-detail")
      localStorage.setItem("lastPageUrl",detailContent.data?detailContent.data.article?detailContent.data.article?detailContent.data.article.url:null:null:null)
      setLoader(prevState => ({ ...prevState, [content.id]: false }))
      return false
    }
  }

  if (typeof window !== "undefined") {
    if (localStorage.getItem("userId")) {
      if (localStorage.getItem("reload") === "false") {
        window.location.reload(false)
        localStorage.setItem("reload", true)
      }
    } else {
      localStorage.setItem("reload", false)
    }
  }

  const RatingComponent = ({ detailComp }) => {
    return (
      <>
        {detailContent.data.university.ratings && (
          <div class="reviews-progress">
            <div class="rating-progress">
              <div
                class="progress-circle"
                data-percentage={parseInt(
                  detailContent.data.university.ratings.academic * 10
                )}
              >
                <svg class="progress-circle__svg" viewport="0 0 2000 2000">
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                </svg>

                <span class="reviews-percentage">
                  {detailContent.data.university.ratings.academic
                    ? parseFloat(
                        detailContent.data.university.ratings.academic
                      ).toFixed(1)
                    : 0}
                </span>
              </div>
              <h3 class="title">Academic</h3>
            </div>
            <div class="rating-progress">
              <div
                class="progress-circle"
                data-percentage={parseInt(
                  detailContent.data.university.ratings.infrastructure * 10
                )}
              >
                <svg class="progress-circle__svg" viewport="0 0 2000 2000">
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                </svg>

                <span class="reviews-percentage">
                  {detailContent.data.university.ratings.infrastructure
                    ? parseFloat(
                        detailContent.data.university.ratings.infrastructure
                      ).toFixed(1)
                    : 0}
                </span>
              </div>
              <h3 class="title">Infrastructure</h3>
            </div>
            <div class="rating-progress">
              <div
                class="progress-circle"
                data-percentage={parseInt(
                  detailContent.data.university.ratings.extracurricular * 10
                )}
              >
                <svg class="progress-circle__svg" viewport="0 0 2000 2000">
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                </svg>

                <span class="reviews-percentage">
                  {detailContent.data.university.ratings.extracurricular
                    ? parseFloat(
                        detailContent.data.university.ratings.extracurricular
                      ).toFixed(1)
                    : 0}
                </span>
              </div>
              <h3 class="title">Extracurricular</h3>
            </div>
            <div class="rating-progress">
              <div
                class="progress-circle"
                data-percentage={parseInt(
                  detailContent.data.university.ratings.accomodation * 10
                )}
              >
                <svg class="progress-circle__svg" viewport="0 0 2000 2000">
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                </svg>

                <span class="reviews-percentage">
                  {detailContent.data.university.ratings.accomodation
                    ? parseFloat(
                        detailContent.data.university.ratings.accomodation
                      ).toFixed(1)
                    : 0}
                </span>
              </div>
              <h3 class="title">Accomodation</h3>
            </div>
            <div class="rating-progress">
              <div
                class="progress-circle"
                data-percentage={parseInt(
                  detailContent.data.university.ratings.faculty * 10
                )}
              >
                <svg class="progress-circle__svg" viewport="0 0 2000 2000">
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                </svg>

                <span class="reviews-percentage">
                  {detailContent.data.university.ratings.faculty
                    ? parseFloat(
                        detailContent.data.university.ratings.faculty
                      ).toFixed(1)
                    : 0}
                </span>
              </div>
              <h3 class="title">Faculty</h3>
            </div>
            <div class="rating-progress">
              <div
                class="progress-circle"
                data-percentage={parseInt(
                  detailContent.data.university.ratings.placement * 10
                )}
              >
                <svg class="progress-circle__svg" viewport="0 0 2000 2000">
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                  <circle
                    class="progress-circle__stroke"
                    r="50%"
                    cx="50%"
                    cy="50%"
                  ></circle>
                </svg>

                <span class="reviews-percentage">
                  {detailContent.data.university.ratings.placement
                    ? parseFloat(
                        detailContent.data.university.ratings.placement
                      ).toFixed(1)
                    : 0}
                </span>
              </div>
              <h3 class="title">Placement</h3>
            </div>
          </div>
        )}
        {/*${process.env.BACKEND_URL}${content.user.profileImage?.url} || */}
        <div class="reviews-view-wrap">
          {detailComp.data &&
            detailComp.data.university.reviews.map((content, index) => (
              <div class="reviews-view" key={index}>
                <div class="reviews-left">
                  <div class="review-fig">
                    {content.user?.profileImage ? (
                      <img
                        src={`${process.env.BACKEND_URL}${content.user.profileImage?.url}`}
                        alt="profileImage"
                      />
                    ) : (
                      <img
                        src={require("../assets/images/default-profile.png")}
                        alt="profileImage"
                      />
                    )}
                  </div>
                  <div class="details">
                    <h3 class="reviwer-name">
                      {content.user?.firstName ? content.user?.firstName : ""}{" "}
                      {content.user?.lastName ? content.user?.lastName : ""}
                    </h3>
                    <h5 class="location">
                      {content.user?.cityName ? content.user?.cityName : ""},{" "}
                      {content.user?.countryName?.countryName
                        ? content.user?.countryName?.countryName
                        : ""}
                    </h5>
                    {content.user?.batch && (
                      <span class="reviwer-batch">
                        Batch-{content.user?.batch ? content.user?.batch : ""}
                      </span>
                    )}
                  </div>
                </div>
                <div class="reviews-right">
                  <span className="ratings"></span>
                  <ReactStars
                    count={5}
                    value={content.averageRating || 0}
                    size={24}
                    edit={false}
                    activeColor="#ffd700"
                  />
                  <h4 class="sub-title">{content.title}</h4>
                  <p class="reviewers-review">
                    <ReactReadMoreReadLess
                      charLimit={150}
                      readMoreText={"Read more"}
                      readLessText={"Read less"}
                    >
                      {content.description}
                    </ReactReadMoreReadLess>
                  </p>
                </div>
              </div>
            ))}
        </div>
      </>
    )
  }

  const reviewOpenCheck = () => {
    if (userID === null) {
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
    } else {
      setWriteReviewOpen(true)
    }
  }

  const renderOverViewDescription = () =>{
    return(
      detailContent.data.university
        .aboutPage.overviewDescription?
        <ReactMarkdown source={ detailContent.data.university
          .aboutPage.overviewDescription.description}
          transformImageUri={(uri) =>
            uri.startsWith("http")
              ? uri
              : `${process.env.BACKEND_URL}${uri}`
          }
          />
          :"" 
    )
  }
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <WriteReview
        open={writeReviewOpen}
        setOpen={setWriteReviewOpen}
        userValue={{ userName: username, userId: userID }}
        universityDetail={
          detailContent.data ? detailContent.data.university : undefined
        }
      />
      {detailContent.loading && (
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      )}
      {!detailContent.loading && (
        <div className="wrapper">
          <Layout title="UniversityDetails" tag="universities">
            <Login
              open={open}
              setOpen={setOpen}
              login={login}
              setLogin={setLogin}
              setUsername={setUsername}
              setUserID={setUserID}
              forgotPassword={forgotPassword}
              setForgotPassword={setForgotPassword}
              errorMessage ={errorMessage}
              setErrorMessage ={setErrorMessage}
              successMessage ={successMessage}
              setSuccessMessage ={setSuccessMessage}
              phoneError ={phoneError}
              setphoneError ={setphoneError}
            />
            <div class="home-banner univ-details-banner study-detail">
              <div class="banner-overlay"></div>

              <div className="university-details-carousel">
                <div class="carousel-wrapper" id="banner-slider">
                  {/* Banner owl carousel */}

                  <OwlCarousel
                    className="owl-carousel owl-theme"
                    autoplay={1}
                    loop
                    margin={-2}
                    nav
                    items={1}
                    mergeFit={true}
                    dots={false}
                  >
                    {detailContent.data ?
                    detailContent.data.university?
                    detailContent.data.university.sliderContent?
                      detailContent.data.university.sliderContent.Images.map(
                        (content, index) => (
                          <div class="item" key={content.id}>
                            <div class="content-wrapper">
                              <div
                                class="univ-details"
                                style={{
                                  "--my-univ-logo": `url(${
                                    detailContent.data.university.universityLogo
                                      ? process.env.BACKEND_URL +
                                        detailContent.data.university
                                          .universityLogo.url
                                      : null
                                  })`,
                                }}
                              >
                                <h4 class="univ-name">
                                  {detailContent.data.university.universityName
                                    ? detailContent.data.university
                                        .universityName
                                    : ""}
                                </h4>
                                <p class="univ-location">
                                  {detailContent.data.university.stateName
                                    .stateName
                                    ? detailContent.data.university.stateName
                                        .stateName
                                    : ""}
                                  ,{" "}
                                  {detailContent.data.university.countryName
                                    .countryName
                                    ? detailContent.data.university.countryName
                                        .countryName
                                    : ""}
                                </p>
                              </div>
                              {content ? (
                                <img
                                  class="univ-fig"
                                  src={`${process.env.BACKEND_URL}${content.url}`}
                                  alt="Images"
                                />
                              ) : (
                                <img
                                  class="univ-fig"
                                  src={require("../assets/images/default-bannar-image.png")}
                                  alt="Images"
                                />
                              )}
                            </div>
                          </div>
                        )
                      ) 
                      :null
                      :null
                      :null
                    }
                  </OwlCarousel>
                </div>
                <div className="wishlist">
                  {/* wishlist component start*/}
                  {detailContent.data && (
                    <div className="wish-icon">
                      {!wishlisted[detailContent.data.university.id] && (
                        <button
                          type="button"
                          onClick={e =>
                            WishlistTrigger(detailContent.data.university, e)
                          }
                        >
                          {loader[detailContent.data.university.id] === true ? (
                            <div
                              className={"centered"}
                              style={{ height: "14px" }}
                            >
                              <BounceIndicator size={14} color={"#0055a4"} />
                            </div>
                          ) : (
                            "Add to wishlist"
                          )}
                        </button>
                      )}

                      {wishlisted[detailContent.data.university.id] && (
                        <button
                          type="button"
                          onClick={e =>
                            UnWishlistTrigger(detailContent.data.university)
                          }
                        >
                          {loader[detailContent.data.university.id] === true ? (
                            <div
                              className={"centered"}
                              style={{ height: "14px" }}
                            >
                              <BounceIndicator size={14} color={"#0055a4"} />
                            </div>
                          ) : (
                            "Added to wishlist"
                          )}
                        </button>
                      )}
                    </div>
                  )}
                  {/* wishlist component end*/}
                </div>
                <div className="head-course-details">
                  {/* Details for the university course*/}

                  {detailContent.data && (
                    <div class="unive-course-details">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="box established">
                          <h4 class="top-text">
                            {detailContent.data.university.establishedIn
                              ? detailContent.data.university.establishedIn
                              : ""}
                          </h4>
                          <p class="bottom-text">established in</p>
                        </div>
                        <div class="box fees">
                          <h4 class="top-text">
                            {detailContent.data.university.tution_fee_ranges[0]
                              ?.range
                              ? detailContent.data.university
                                  .tution_fee_ranges[0].range
                              : ""}
                          </h4>
                          <p class="bottom-text">Tution Fee</p>
                        </div>
                        <div class="box type-institute">
                          <h4 class="top-text">
                            {detailContent.data.university.institutionType
                              ? detailContent.data.university.institutionType
                              : ""}
                          </h4>
                          <p class="bottom-text">Institution Type</p>
                        </div>
                        <div class="box ranking">
                          <h4 class="top-text">
                            {detailContent.data.university
                              .worldUniversityRanking
                              ? detailContent.data.university
                                  .worldUniversityRanking
                              : ""}
                          </h4>
                          <p class="bottom-text">
                            QS World University Rankings
                          </p>
                        </div>
                        <div class="box certified">
                          <h4 class="top-text">
                            {detailContent.data.university.accreditedBy
                              ? detailContent.data.university.accreditedBy
                              : ""}
                          </h4>
                          <p class="bottom-text">Accredited by</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*Details of tthe university course end*/}
                </div>
              </div>
            </div>

            <main className="home university-details study">
              <div className="main-content univ-details-main" id="main-div">
                <div className="container">
                  {/*2coulumn*/}
                  <div
                    className="tab-pane fade show active"
                    id="activity"
                    role="tabpanel"
                    aria-labelledby="pills-activity-tab"
                  >
                    <div className="activity-tabs">
                      <ul className="nav nav-pills">
                        <li className="nav-item" role="presentation">
                          <a
                            href="#section1"
                            className={
                              viewAll
                                ? aboutPage
                                  ? `nav-link active`
                                  : `nav-link active`
                                : showAdmission
                                ? `nav-link`
                                : showReview
                                ? `nav-link`
                                : `nav-link active`
                            }
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#profile"
                            role="tab"
                            aria-controls="pills-all"
                            aria-selected="true"
                            onClick={e => AboutPageFun(e)}
                          >
                            {/* About */}
                          </a>
                          {/* {!viewAll && !showAdmission && !showReview && ( */}
                            <div className="sub-navs">
                              <ul className="list list-unstyled">
                                <li>
                                  <a
                                    id="#section11"
                                    className={
                                      active.id === "#section11"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Overview
                                  </a>
                                </li>
                                <li>
                                  <a
                                    id="#section21"
                                    className={
                                      active.id === "#section21"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Programmes
                                  </a>
                                </li>
                                <li>
                                  <a
                                    id="#section31"
                                    className={
                                      active.id === "#section31"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Admission{" "}
                                  </a>{" "}
                                </li>
                                {/* <li>
                                  <a
                                    id={`#section41`}
                                    className={
                                      active.id === "#section41"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    University Services
                                  </a>
                                </li> */}
                                <li>
                                  <a
                                    id={`#section51`}
                                    className={
                                      active.id === "#section51"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Campus
                                  </a>
                                </li>
                                <li>
                                  <a
                                    id={`#section61`}
                                    className={
                                      active.id === "#section61"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Accredition
                                  </a>
                                </li>
                                <li>
                                  <a
                                    id={`#section81`}
                                    className={
                                      active.id === "#section81"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Contact
                                  </a>
                                </li>
                                {/* <li>
                                  <a
                                    id={`#section71`}
                                    className={
                                      active.id === "#section71"
                                        ? "active"
                                        : null
                                    }
                                    onClick={e => handleClick(e)}
                                  >
                                    Reviews
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                          {/* )} */}
                        </li>
                        {/*<li className="nav-item" role="presentation">
                          <a
                            href="#"
                            className={viewAll ? `nav-link active` : `nav-link`}
                            id="pills-all-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#allactivity"
                            role="tab"
                            aria-controls="pills-all"
                            aria-selected="true"
                            onClick={e => ViewAllProgrammes(e)}
                          >
                            Programmes
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            href="#"
                            className={
                              showAdmission ? `nav-link active` : `nav-link`
                            }
                            id="pills-answers-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#answers"
                            role="tab"
                            aria-controls="pills-answers"
                            aria-selected="true"
                            onClick={e => ViewAdmission(e)}
                          >
                            Admission
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            href="#"
                            className={
                              showReview ? `nav-link active` : `nav-link`
                            }
                            id="pills-questions-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#questions"
                            role="tab"
                            aria-controls="pills-questions"
                            aria-selected="true"
                            onClick={e => ReviewDetails(e)}
                          >
                            Review
                          </a>
                        </li>*/}
                      </ul>
                      {programmeDetailContent.loading && (
                        <div className={"centered"}>
                          <BounceIndicator size={25} color={"#0055a4"} />
                        </div>
                      )}

                      {!programmeDetailContent.loading && (
                        <div className="tab-content" id="pills-tabContent">
                          {!programmeDetailContent.loading && showReview && (
                            <div
                              className="tab-pane fade show active"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                            >
                              <div className="content-wrap main-review">
                                <div class="contents">
                                  <div className="rev-title-button">
                                    <h4 class="content-heading">Reviews</h4>
                                    <a
                                      onClick={() => {
                                        reviewOpenCheck()
                                      }}
                                      class="write-review-title"
                                    >
                                      Write a Review
                                    </a>
                                  </div>
                                  <RatingComponent
                                    detailComp={programmeDetailContent}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {showAdmission && detailContent.data && (
                            <div
                              className="tab-pane fade show active"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                            >
                              <div class="content-wrap admission">
                                {detailContent.data?.university?.admission
                                  ?.description && (
                                  <div class="contents">
                                    <div class="course-description">
                                      <h4 class="description-heading">
                                        {detailContent.data?.university?.admission
                                          ?.description?.title
                                          ? detailContent.data?.university
                                              ?.admission?.description?.title
                                          : ""}
                                      </h4>
                                      {detailContent.data.university?.admission?.description?.subContent.map(
                                        (content, index) => (
                                          <div key={index}>
                                            <h5 class="sub-description">
                                              {parse(
                                                content.title
                                                  ? content.title
                                                  : ""
                                              )}
                                            </h5>

                                            {/*    <p class="programme-desc">*/}
                                            {
                                              content.description
                                                ? <ReactMarkdown source={content.description}
                                                      transformImageUri={(uri) =>
                                                        uri.startsWith("http")
                                                          ? uri
                                                          : `${process.env.BACKEND_URL}${uri}`
                                                      }
                                                />
                                                : ""
                                            }
                                            {/*<ReactMarkdown source={content.description} className="programme-desc" />*/}
                                            {index === 1 && <br />}

                                            {/*    </p>*/}
                                          </div>
                                        )
                                      )}

                                      {/*<p class="programme-desc">
                           The admissions policy is not very rigid especially for
                           the international students that do not have any
                           specific or additional requirements
                         </p>
                         <p class="programme-desc">
                           <strong>IELTS</strong> or
                           <strong>TOEFL</strong> scores are accepted by the
                           university as proof of language proficiency.
                         </p>
                         <p class="programme-desc">
                           The University offers pre-sessional Academic English
                           programs for international applicants who do not
                           fulfill the English language proficiency requirements.
                         </p>
                         <p class="programme-desc">
                           Selected applicants receive admission notification
                           through email in a period of one week.
                         </p>
                         <p class="programme-desc">
                           A good honors degree or equivalent qualification is
                           required to apply for a PG course.
                         </p>
                         <br />
                         <h5 class="sub-description">
                           Ecole de Management de Normandie University COVID-19
                           update
                         </h5>
                         <p class="programme-desc">
                           The enrollment and teaching dates in September will
                           not be extended due to COVID-19
                         </p>
                         <p class="programme-desc">
                           Since the university has flexible intakes and offers
                           an opportunity to the candidates to defer to January
                           or May, so September intake will not be postponed.
                 </p>*/}
                                    </div>
                                  </div>
                                )}

                                <div class="contents">
                                  <div class="course-description">
                                    <h4 class="description-heading">
                                      {
                                        detailContent.data?.university?.admission
                                          ?.requirements?.title
                                      }
                                    </h4>
                                    {detailContent.data?.university?.admission
                                      ?.requirements?.subRequirement.length !==
                                      0 && (
                                      <div class="admission-requirements">
                                        <div class="admission-requirement">
                                          {detailContent.data?.university
                                            ?.admission?.requirements
                                            ?.subRequirement.length !== 0 &&
                                            detailContent.data.university?.admission?.requirements?.subRequirement.map(
                                              (req, index) =>
                                                index <= 1 ? (
                                                  <div key={index}>
                                                    <h5 class="sub-description">
                                                      {req.title
                                                        ? req.title
                                                        : ""}
                                                    </h5>
                                                    {index === 1 ? (
                                                      <div class="academic">
                                                        <p class="programme-desc">
                                                          <ReactMarkdown
                                                            source={
                                                              req.description
                                                                ? req.description
                                                                : ""
                                                            }
                                                            transformImageUri={(uri) =>
                                                              uri.startsWith("http")
                                                                ? uri
                                                                : `${process.env.BACKEND_URL}${uri}`
                                                            }
                                                          />
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <p class="programme-desc">
                                                          <ReactMarkdown
                                                            source={
                                                              req.description
                                                                ? req.description
                                                                : ""
                                                            }
                                                            transformImageUri={(uri) =>
                                                              uri.startsWith("http")
                                                                ? uri
                                                                : `${process.env.BACKEND_URL}${uri}`
                                                            }
                                                          />
                                                        </p>
                                                        <br />
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : null
                                            )}

                                          <h5 class="sub-description meet-all-requirement">
                                            {detailContent.data?.university?.admission?.requirements
                                              ?.weblinkTitle
                                              ? detailContent.data?.university
                                                  ?.admission?.requirements
                                                  ?.weblinkTitle
                                              : ""}
                                          </h5>
                                          
                                            {detailContent.data?.university
                                              .admission?.requirements?.weblink
                                              ? <a
                                              class="requirement-link"
                                              href={detailContent.data?.university
                                                ?.admission?.requirements
                                                ?.weblink} target="_blank"
                                            >{detailContent.data?.university
                                                  ?.admission?.requirements
                                                  ?.weblink}</a>
                                              : ""}
                                          
                                        </div>
                                        <div class="admission-requirement">
                                          {detailContent.data?.university?.admission?.requirements?.subRequirement.map(
                                            (req, index) =>
                                              index >= 2 ? (
                                                <div key={index}>
                                                  <h5 class="sub-description">
                                                    {req.title ? req.title : ""}
                                                  </h5>
                                                  {req.image1.length ||
                                                  req.image2.length ? (
                                                    <div class="english-requirement">
                                                      <div class="left box toefl">
                                                        {req.image1.length !==
                                                          0 && (
                                                          <img
                                                            src={`${process.env.BACKEND_URL}${req.image1[0]?.url}`}
                                                            alt=""
                                                          />
                                                        )}
                                                        <h2 class="left-text">
                                                          {req.examscore
                                                            ? req.examscore
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div class="right box ielts">
                                                        {req.image2.length !==
                                                          0 && (
                                                          <img
                                                            src={`${process.env.BACKEND_URL}${req.image2[0]?.url}`}
                                                            alt=""
                                                          />
                                                        )}
                                                        <h2 class="left-text">
                                                          {req.examScore2
                                                            ? req.examScore2
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                  {!req.image1.length &&
                                                    !req.image2.length && (
                                                      <div class="other-requirements">
                                                        <p>
                                                          <ReactMarkdown
                                                            source={
                                                              req.description
                                                                ? req.description
                                                                : ""
                                                            }
                                                            transformImageUri={(uri) =>
                                                              uri.startsWith("http")
                                                                ? uri
                                                                : `${process.env.BACKEND_URL}${uri}`
                                                            }
                                                          />
                                                        </p>
                                                      </div>
                                                    )}
                                                </div>
                                              ) : null
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {detailContent.data.university?.admission
                                  ?.applicationDeadline && (
                                  <div class="contents">
                                    <div class="course-description">
                                      <h4 class="description-heading">
                                        {detailContent.data.university?.admission
                                          ?.applicationDeadline?.title
                                          ? detailContent.data?.university
                                              ?.admission?.applicationDeadline
                                              ?.title
                                          : ""}
                                      </h4>
                                      <h5 class="sub-description">
                                        {detailContent.data.university?.admission
                                          ?.applicationDeadline?.subtitle
                                          ? detailContent.data?.university
                                              ?.admission?.applicationDeadline
                                              ?.subtitle
                                          : ""}
                                      </h5>
                                      <div class="application-deadline">
                                        <div class="instruction application-left">
                                          {detailContent.data?.university
                                            ?.admission?.applicationDeadline
                                            ?.programmeDescription
                                            ? <ReactMarkdown source={detailContent.data?.university
                                              ?.admission?.applicationDeadline
                                              ?.programmeDescription}
                                              transformImageUri={(uri) =>
                                                uri.startsWith("http")
                                                  ? uri
                                                  : `${process.env.BACKEND_URL}${uri}`
                                              }
                                              />
                                            : ""}
                                        </div>
                                        <div class="instruction application-right">
                                          {detailContent.data.university
                                            ?.admission?.applicationDeadline
                                            ?.sessionDescription
                                            ? <ReactMarkdown 
                                                source={
                                                  detailContent.data.university
                                                    ?.admission?.applicationDeadline
                                                    ?.sessionDescription
                                                }
                                                transformImageUri={(uri) =>
                                                  uri.startsWith("http")
                                                    ? uri
                                                    : `${process.env.BACKEND_URL}${uri}`
                                                }
                                                />
                                            : ""}
                                        </div>
                                      </div>
                                      <div class="course-application-deadline">
                                        <div class="course-application left">
                                          <table class="table deadline">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {detailContent.data.university
                                                    ?.admission
                                                    ?.applicationDeadline
                                                    ?.programmeTitle
                                                    ? detailContent.data
                                                        ?.university?.admission
                                                        ?.applicationDeadline
                                                        ?.programmeTitle
                                                    : ""}
                                                </td>
                                                <td>
                                                  {detailContent.data.university
                                                    ?.admission
                                                    ?.applicationDeadline
                                                    ?.programmeTitle2
                                                    ? detailContent.data
                                                        ?.university?.admission
                                                        ?.applicationDeadline
                                                        ?.programmeTitle2
                                                    : ""}
                                                </td>
                                              </tr>

                                              {detailContent.data.university?.admission?.applicationDeadline?.programmes.map(
                                                (p, index) => (
                                                  <tr key={index}>
                                                    <td>
                                                      {p.programme
                                                        ? p.programme
                                                        : ""}
                                                    </td>
                                                    <td>
                                                      {DateFunc(
                                                        p.applicationDeadlines
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        <div class="course-application right">
                                          <table class="table deadline">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {
                                                    detailContent.data
                                                      ?.university?.admission
                                                      ?.applicationDeadline
                                                      ?.sessionTitle
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    detailContent.data
                                                      ?.university?.admission
                                                      ?.applicationDeadline
                                                      ?.sessionTitle2
                                                  }
                                                </td>
                                              </tr>
                                              {detailContent.data.university?.admission?.applicationDeadline?.sessions?.map(
                                                (s, index) => (
                                                  <tr key={index}>
                                                    <td>
                                                      {s.session
                                                        ? s.session
                                                        : ""}
                                                    </td>
                                                    <td>
                                                      {DateFunc(
                                                        s.applicationDeadlines
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {detailContent.data.university?.admission
                                  ?.admissionToTopProgrammes && (
                                  <div class="contents">
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {viewAll && programmeDetailContent.data && (
                            <div
                              className="tab-pane fade show active"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                            >
                              <div className="content-wrap">
                                {!Inprogramme &&
                                  programmeDetailContent.data.university
                                    ?.programmes && (
                                    <div className="contents">
                                      <h4 className="content-heading">
                                        All Programmes
                                      </h4>
                                      {programmeDetailContent.data.university
                                        ?.programmes.length !== 0 && (
                                        <div className="univ-programmes">
                                          {programmeDetailContent.data.university?.programmes.map(
                                            (content, index) => (
                                              <div
                                                className="programme"
                                                key={index}
                                              >
                                                <div
                                                  className="  
                                        programme-heading
                                            d-flex
                                            justify-content-between
                                            align-items-center"
                                                >
                                                  <h4 className="course">
                                                    {content.Title
                                                      ? content.Title
                                                      : ""}
                                                  </h4>
                                                  {/* <a
                                                    href="javascript:;"
                                                    class="arrow-right"
                                                  >
                                                    <i class="fas fa-arrow-right"></i>
                                                  </a> */}
                                                </div>
                                                <div className="course-body">
                                                  <div className="details">
                                                    <div className="course-content">
                                                      <h4 className="course-fees fees">
                                                        {content
                                                          .ProgrammeInfo[0]?.Content
                                                          ? content
                                                              .ProgrammeInfo[0]?.Content
                                                          : ""}
                                                      </h4>
                                                      <p className="course-year">
                                                        {content
                                                          .ProgrammeInfo[0]
                                                          .Title
                                                          ? content
                                                              .ProgrammeInfo[0]
                                                              .Title
                                                          : ""}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="details year">
                                                    <div className="course-content">
                                                      <h4 className="course-fees year">
                                                        {content
                                                          .ProgrammeInfo[1]?.Content
                                                          ? content
                                                              .ProgrammeInfo[1]?.Content
                                                          : ""}
                                                      </h4>
                                                      <p className="course-year">
                                                        {content
                                                          .ProgrammeInfo[1]?.Title
                                                          ? content
                                                              .ProgrammeInfo[1]?.Title
                                                          : ""}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="details">
                                                    <div className="course-content">
                                                      <h4 className="course-fees date">
                                                        {content
                                                          .ProgrammeInfo[2]?.Date
                                                          ? content
                                                              .ProgrammeInfo[2]?.Date
                                                          : ""}
                                                      </h4>
                                                      <p className="course-year">
                                                        {content
                                                          .ProgrammeInfo[2]?.Title
                                                          ? content
                                                              .ProgrammeInfo[2]?.Title
                                                          : ""}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="details rank">
                                                    <div className="course-content rank">
                                                      <h4 className="course-fees rank">
                                                        {content
                                                          .ProgrammeInfo[3]?.Content
                                                          ? content
                                                              .ProgrammeInfo[3]?.Content
                                                          : ""}
                                                      </h4>
                                                      <p className="course-year">
                                                        {content
                                                          .ProgrammeInfo[3]?.Title
                                                          ? content
                                                              .ProgrammeInfo[3]?.Title
                                                          : ""}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                {programmeDetailContent.data.university
                                  .programmes &&
                                  Inprogramme && (
                                    <div className="contents">
                                      {/* {programmeDetailContent.data.university.programmes
                                        .filter(
                                          e => e.id === programmeDescription
                                        )
                                        .map((content, index) => (
                                          <div
                                            class="tab-pane fade show"
                                            id="allactivity"
                                            role="tabpanel"
                                            aria-labelledby="pills-activity-tab"
                                            key={content.id}
                                          >
                                            <div class="content-wrap all-programmes">
                                              <div class="contents">
                                                <h4 class="content-heading">
                                                  {content.Title
                                                    ? content.Title
                                                    : ""}
                                                </h4>
                                                <div class="course-description">
                                                  <h4 class="description-heading">
                                                    Programme Description
                                                  </h4>

                                                  <ReactMarkdown
                                                    className="programme-desc"
                                                    source={
                                                      content.programme_page
                                                        ?.programmeDescription
                                                        ?.description
                                                        ? content.programme_page
                                                            ?.programmeDescription
                                                            ?.description
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="contents">
                                                <h4 class="description-heading">
                                                  {content.programme_page
                                                    ?.programStructureContent
                                                    ?.title
                                                    ? content.programme_page
                                                        ?.programStructureContent
                                                        ?.title
                                                    : ""}
                                                </h4>
                                                <div class="course-include">
                                                  <h4 class="course-inlude-heading">
                                                    {content.programme_page
                                                      ?.programStructureContent
                                                      ?.subtitle
                                                      ? content.programme_page
                                                          ?.programStructureContent
                                                          ?.subtitle
                                                      : ""}
                                                  </h4>

                                                  <div class="course-contents">
                                                    {content.programme_page
                                                      .programStructureContent &&
                                                      content.programme_page?.programStructureContent?.imageContent.map(
                                                        (img, index) => (
                                                          <div
                                                            class="course"
                                                            key={index}
                                                          >
                                                            {img.image && (
                                                              <img
                                                                src={`${process.env.BACKEND_URL}${img.image?.url}`}
                                                                alt="imagevalue"
                                                              />
                                                            )}
                                                            <h2 class="course-title">
                                                              {img.title
                                                                ? img.title
                                                                : ""}
                                                            </h2>
                                                          </div>
                                                        )
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="contents">
                                                <h4 class="description-heading">
                                                  {content.programme_page
                                                    ?.feeFundingContent?.title
                                                    ? content.programme_page
                                                        ?.feeFundingContent
                                                        .title
                                                    : ""}
                                                </h4>
                                                <div class="course-include fees-fund">
                                                  <div class="course-left">
                                                    <h4 class="course-inlude-heading">
                                                      {content?.programme_page
                                                        ?.feeFundingContent
                                                        ?.subtitle
                                                        ? content.programme_page
                                                            ?.feeFundingContent
                                                            ?.subtitle
                                                        : ""}
                                                    </h4>

                                                    {content.programme_page
                                                      ?.feeFundingContent
                                                      ?.subContent &&
                                                      content.programme_page?.feeFundingContent?.subContent.map(
                                                        (fee, index) => (
                                                          <div class="tution-fees">
                                                            <div
                                                              class="internationl"
                                                              key={index}
                                                            >
                                                              {fee.image && (
                                                                <img
                                                                  src={`${process.env.BACKEND_URL}${fee.image?.url}`}
                                                                  alt="fee"
                                                                />
                                                              )}
                                                              <div class="international-fee">
                                                                <h3 class="title">
                                                                  {fee.title
                                                                    ? fee.title
                                                                    : ""}
                                                                </h3>
                                                                <p class="fee">
                                                                  {fee.subtitle
                                                                    ? fee.subtitle
                                                                    : ""}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                  </div>

                                                  <div class="course-right">
                                                    {content.programme_page
                                                      ?.feeFundingContent
                                                      ?.subContent1 ? (
                                                      <h4 class="course-inlude-heading">
                                                        Funding
                                                      </h4>
                                                    ) : null}

                                                    <p>
                                                      {parse(
                                                        content.programme_page
                                                          ?.feeFundingContent
                                                          ?.subContent1
                                                          ? content
                                                              .programme_page
                                                              ?.feeFundingContent
                                                              ?.subContent1
                                                          : ""
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))} */}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}

                          {((!viewAll && !showAdmission && !showReview) ||
                            aboutPage) && (
                            <div
                              className="tab-pane fade show active"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                            >
                              {/*<!-- About Content -->*/}
                              {detailContent.data &&
                                detailContent.data.university.aboutPage && (
                                  <div className="content-wrap">
                                    <div className="contents" id="section11">
                                      <h4 class="content-heading">
                                        {
                                          detailContent.data.university
                                            .aboutPage.overviewDescription
                                            ?.title
                                        }
                                      </h4>
                                      <p class="content">
                                        {renderOverViewDescription()}
                                      </p>
                                    </div>

                                    {detailContent.data.university
                                      .programmes && (
                                      <div className="contents" id="section21">
                                        <h4 className="content-heading">
                                          Programmes
                                        </h4>
                                        <div className="univ-programmes">
                                          {detailContent.data.university.programmes.map(
                                            (content, index) => (
                                              <div
                                                className="programme"
                                                key={index}
                                              >
                                                <div
                                                  className="  
                                              programme-heading
                                                  d-flex
                                                  justify-content-between
                                                  align-items-center"
                                                >
                                                  <h4 className="course">
                                                    {content.Title
                                                      ? content.Title
                                                      : ""}
                                                  </h4>
                                                  {/* <a
                                                    href="javascript:;"
                                                    class="arrow-right"
                                                  >
                                                    <i class="fas fa-arrow-right"></i>
                                                  </a> */}
                                                </div>
                                                <div className="course-body">
                                                  <div className="details">
                                                    {content.ProgrammeInfo
                                                      .length !== 0 &&
                                                      content
                                                        .ProgrammeInfo[0] && (
                                                        <div className="course-content">
                                                          <h4 className="course-fees fees">
                                                            {content
                                                              .ProgrammeInfo[0]
                                                              .Content
                                                              ? content
                                                                  .ProgrammeInfo[0]
                                                                  .Content
                                                              : ""}
                                                          </h4>
                                                          <p className="course-year">
                                                            {content
                                                              .ProgrammeInfo[0]
                                                              .Title
                                                              ? content
                                                                  .ProgrammeInfo[0]
                                                                  .Title
                                                              : ""}
                                                          </p>
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="details year">
                                                    {content.ProgrammeInfo
                                                      .length !== 0 &&
                                                      content
                                                        .ProgrammeInfo[1] && (
                                                        <div className="course-content">
                                                          <h4 className="course-fees year">
                                                            {
                                                              content
                                                                .ProgrammeInfo[1]
                                                                .Content
                                                            }
                                                          </h4>
                                                          <p className="course-year">
                                                            {
                                                              content
                                                                .ProgrammeInfo[1]
                                                                .Title
                                                            }
                                                          </p>
                                                        </div>
                                                      )}
                                                  </div>
                                                  {content.ProgrammeInfo
                                                    .length !== 0 &&
                                                    content
                                                      .ProgrammeInfo[2] && (
                                                      <div className="details">
                                                        <div className="course-content">
                                                          <h4 className="course-fees date">
                                                            {content
                                                              .ProgrammeInfo[2]
                                                              .Date
                                                              ? content
                                                                  .ProgrammeInfo[2]
                                                                  .Date
                                                              : ""}
                                                          </h4>
                                                          <p className="course-year">
                                                            {content
                                                              .ProgrammeInfo[2]
                                                              .Title
                                                              ? content
                                                                  .ProgrammeInfo[2]
                                                                  .Title
                                                              : ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    )}
                                                  {content.ProgrammeInfo
                                                    .length !== 0 &&
                                                    content
                                                      .ProgrammeInfo[3] && (
                                                      <div className="details rank">
                                                        <div className="course-content rank">
                                                          <h4 className="course-fees rank">
                                                            {content
                                                              .ProgrammeInfo[3]
                                                              .Content
                                                              ? content
                                                                  .ProgrammeInfo[3]
                                                                  .Content
                                                              : ""}
                                                          </h4>
                                                          <p className="course-year">
                                                            {content
                                                              .ProgrammeInfo[3]
                                                              .Title
                                                              ? content
                                                                  .ProgrammeInfo[3]
                                                                  .Title
                                                              : ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            )
                                          )}

                                          <div
                                            className="cta-secondary  view-all-course"
                                            onClick={e => ViewAllProgrammes(e)}
                                          >
                                            <button
                                              className="view-all"
                                              type="button"
                                            >
                                              View all Programmes
                                              <span>
                                                <i className="fas fa-arrow-right"></i>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {detailContent.data.university.aboutPage
                                      .admissionContent && (
                                      <div class="contents" id="section31">
                                        <h4 class="content-heading">
                                          {
                                            detailContent.data.university
                                              .aboutPage.admissionContent?.title
                                          }
                                        </h4>
                                        <p class="content">
                                          {
                                            detailContent.data.university
                                              .aboutPage.admissionContent
                                              ? <ReactMarkdown source={detailContent.data.university
                                                .aboutPage.admissionContent.description}
                                                transformImageUri={(uri) =>
                                                  uri.startsWith("http")
                                                    ? uri
                                                    : `${process.env.BACKEND_URL}${uri}`
                                                }
                                                />
                                              : ""
                                          }
                                        </p>
                                        <div class="sub-contents">
                                          <h4 class="sub-content-heading">
                                            {detailContent.data.university.aboutPage.admissionContent.subContent.map(
                                              e => (e.title ? e.title : "")
                                            )}
                                          </h4>
                                          <p class="sub-content">
                                            {detailContent.data.university.aboutPage.admissionContent.subContent.map(
                                              e =>
                                                e.description
                                                  ? <ReactMarkdown source={e.description} 
                                                      transformImageUri={(uri) =>
                                                        uri.startsWith("http")
                                                          ? uri
                                                          : `${process.env.BACKEND_URL}${uri}`
                                                      }
                                                  />
                                                  : ""
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    )}

                                    {detailContent.data.university.aboutPage
                                      .universityServicesContent && (
                                      <div class="contents" id="section41">
                                        <h4 class="content-heading">
                                          {
                                            detailContent.data.university
                                              .aboutPage
                                              .universityServicesContent
                                              ?.mainTitle
                                          }
                                        </h4>
                                        {detailContent.data.university.aboutPage.universityServicesContent.subContent.map(
                                            e =>
                                                <>
                                                    <div class="univ-services">
                                                        <div class="sub-contents">
                                                            <h4 class="sub-content-heading">
                                                            {" "}
                                                            {
                                                                e.title?e.title:""
                                                            }
                                                            </h4>
                                                        <p class="sub-content">
                                                        {
                                                        <ReactMarkdown
                                                        source={e.description?e.description:""}
                                                            transformImageUri={(uri) =>
                                                            uri.startsWith("http")
                                                                ? uri
                                                                : `${process.env.BACKEND_URL}${uri}`
                                                            }
                                                            />
                                                        }
                                                        </p>
                                                        </div>
                                                        <div class="univ-services-fig">
                                                            {e.images.map(
                                                                (each, index) =>
                                                                    (index === 0 ||
                                                                    index === 1) && (
                                                                    <div class="fig">
                                                                        {each && (
                                                                        <img
                                                                            src={`${process.env.BACKEND_URL}${each.url}`}
                                                                            alt="image"
                                                                        />
                                                                        )}
                                                                    </div>
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                        )} 
                                      </div>
                                    )}
                                    {universityCampusesContent.data &&
                                      universityCampusesContent.data.university
                                        .aboutPage.universities.length ?(
                                        <div class="contents" id="section51">
                                          <h4 class="content-heading">
                                            Campuses
                                          </h4>

                                          {/* campus content start*/}
                                          <div class="campus-carousel">
                                            <div id="campus-carousel">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme"
                                                autoplay={0}
                                                loop
                                                margin={12}
                                                nav={true}
                                                items={2}
                                                dots={false}
                                                responsiveClass={true}
                                                responsive={{
                                                  0: {
                                                    items: 1,
                                                  },
                                                  700: {
                                                    items: 1,
                                                  },
                                                  800: {
                                                    items: 2,
                                                  },
                                                }}
                                              >
                                                {universityCampusesContent.data &&
                                                  universityCampusesContent.data
                                                    .university.aboutPage
                                                    .universities.length &&
                                                  universityCampusesContent.data.university.aboutPage.universities.map(
                                                    (content, index) => (
                                                      <div
                                                        class="item"
                                                        key={index}
                                                      >
                                                        <Link
                                                          to={`/universities/${content.url}/`}
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                        >
                                                          <div class="campus">
                                                            {content.thumbnailImage ? (
                                                              <img
                                                                src={`${process.env.BACKEND_URL}${content.thumbnailImage?.url}`}
                                                                alt="campusImage"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={require("../assets/images/default-university-thumbnail.png")}
                                                                alt="campusImage"
                                                              />
                                                            )}
                                                            <div class="campus-details">
                                                              <h3 class="campus-name">
                                                                {content.universityName
                                                                  ? content.universityName
                                                                  : ""}
                                                              </h3>
                                                              <p class="campus-location">
                                                                {content
                                                                  .aboutPage
                                                                  ?.contactInformation
                                                                  ?.address
                                                                  ? content
                                                                      .aboutPage
                                                                      ?.contactInformation
                                                                      ?.address
                                                                  : ""}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </div>
                                                    )
                                                  )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                          {/* campus content end*/}
                                        </div>
                                      ):null}

                                    {detailContent.data.university.aboutPage
                                      .accreditationContent && (
                                      <div class="contents" id="section61">
                                        <h4 class="content-heading">
                                          {
                                            detailContent.data.university
                                              .aboutPage.accreditationContent
                                              ?.title
                                          }
                                        </h4>
                                        <p class="content">
                                          {
                                            detailContent.data.university
                                              .aboutPage.accreditationContent
                                              ? <ReactMarkdown source={detailContent.data.university
                                                .aboutPage.accreditationContent.description}
                                                transformImageUri={(uri) =>
                                                  uri.startsWith("http")
                                                    ? uri
                                                    : `${process.env.BACKEND_URL}${uri}`
                                                }
                                                />
                                                :""
                                          }
                                        </p>
                                      </div>
                                    )}

                                    {detailContent.data.university.aboutPage
                                      .studentLifeContent && (
                                      <div class="contents">
                                        <h4 class="content-heading">
                                          {
                                            detailContent.data.university
                                              .aboutPage.studentLifeContent
                                              ?.title
                                          }
                                        </h4>
                                        <p class="content"></p>
                                        {detailContent.data.university.aboutPage
                                          .studentLifeContent.subContent
                                          .length !== 0 &&
                                          detailContent.data.university.aboutPage.studentLifeContent.subContent.map(
                                            (e, index) => (
                                              <div class="sub-contents">
                                                <h4 class="sub-content-heading">
                                                  {e.title ? e.title : ""}
                                                </h4>
                                                <p class="sub-content">
                                                  {e.description
                                                    ? <ReactMarkdown source={e.description}
                                                          transformImageUri={(uri) =>
                                                            uri.startsWith("http")
                                                              ? uri
                                                              : `${process.env.BACKEND_URL}${uri}`
                                                          }
                                                    />
                                                    : ""}
                                                </p>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    )}
                                    {/* {detailContent.data && (
                                      <div class="contents" id="section71">
                                        <div className="rev-title-button">
                                          <h4 class="content-heading">
                                            Reviews
                                          </h4>
                                          <a
                                            onClick={() => {
                                              reviewOpenCheck()
                                            }}
                                            class="write-review-title"
                                          >
                                            Write a Review
                                          </a>
                                        </div>

                                        <RatingComponent
                                          detailComp={detailContent}
                                        />

                                        {detailContent.data &&
                                        detailContent.data.university.reviews
                                          .length ? (
                                          <div className="univ-programmes">
                                            <div
                                              className="view-all-course"
                                              onClick={e => ReviewDetails(e)}
                                            >
                                              <button
                                                className="view-all"
                                                type="button"
                                              >
                                                View all Reviews
                                                <span>
                                                  <i className="fas fa-arrow-right"></i>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    )} */}

                                    <div class="contents" id="section81">
                                      <h4 class="content-heading">Contact</h4>
                                      {detailContent.data.university.aboutPage
                                        .geoLocation && (
                                        <div
                                          class="location-map"
                                          style={{ position: "relative" }}
                                        >
                                          <div
                                            style={{
                                              height: "100%",
                                              width: "100%",

                                              marginBottom: "20px",
                                            }}
                                          >
                                            <GoogleMap
                                              housingDetails={false}
                                              latitude={
                                                detailContent.data.university
                                                  .aboutPage.geoLocation
                                                  ?.latitude
                                              }
                                              longitude={
                                                detailContent.data.university
                                                  .aboutPage.geoLocation
                                                  ?.longitude
                                              }
                                            />
                                          </div>{" "}
                                        </div>
                                      )}
                                      <div class="location-details">
                                        <p class="location-title">
                                          Phone:{" "}
                                          <span>
                                            {" "}
                                            {
                                              detailContent.data.university
                                                .aboutPage.contactInformation
                                                ?.phone
                                            }
                                          </span>
                                        </p>

                                        <p class="location-title">
                                          Address:
                                          <span>
                                            {
                                              detailContent.data.university
                                                .aboutPage.contactInformation
                                                ?.address
                                            }
                                          </span>
                                        </p>

                                        <p class="location-title">
                                          Website:
                                          <span>
                                           
                                              {detailContent.data.university
                                                .aboutPage.contactInformation
                                                .website
                                                ?  <a href={detailContent.data.university
                                                  .aboutPage
                                                  .contactInformation.website} target="_blank">{detailContent.data.university
                                                    .aboutPage
                                                    .contactInformation.website}</a>
                                                : ""}
                                            
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </Layout>
        </div>
      )}
    </>
  )
}

export default Universitydetail

export const query = graphql`
  query universityWithID($pageID: String) {
    strapiUniversities(id: { eq: $pageID }) {
      universityName

      metaDescription
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`