import React, { useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { useMutation, useQuery } from "@apollo/client"
import popup_logo from "../assets/images/popup_logo.png"
import info_logo from "../assets/images/info_icon.png"
import error_logo from "../assets/images/error_icon.png"
import { Link } from "gatsby"
import { validateEmailID, validatePassword } from "../../utils"
import close_image from "../assets/images/icon-close.png"
import BounceIndicator from "react-activity/lib/Bounce"
import ReactStars from "react-rating-stars-component"
import { DropdownButton, Dropdown } from "react-bootstrap"
// import { Field, reduxForm } from "redux-form";

import { CREATE_UNIVERSITY_REVIEW } from "../components/graphql"
import { event } from "jquery"
import { set } from "js-cookie"

export default function FormDialog({
  open,
  setOpen,
  userValue,
  universityDetail,
}) {
  const [saveLoading, setSaveLoading] = useState(false)
  const [ratingError, setRatingError] = useState("")
  const [userRoleError, setUserRoleError] = useState("")
  const [message, setMessage] = useState("")
  const [messageError, setMessageError] = useState("")
  const [title, setTitle] = useState("")
  const [titleError, setTitleError] = useState("")
  const [academicRate, setAcademicRate] = useState(0)
  const [infrastructureRate, setInfrastructureRate] = useState(0)
  const [extracurricularRate, setExtracurricularRate] = useState(0)
  const [accomodationRate, setAccomodationRate] = useState(0)
  const [facultyRate, setFacultyRate] = useState(0)
  const [placementRate, setPlacementRate] = useState(0)
  const [avgRating, setAvgRating] = useState(0)
  const [createReview] = useMutation(CREATE_UNIVERSITY_REVIEW)

  const userId = userValue.userId
  console.log("user---------id", userId)
  console.log("universityDetail", universityDetail)

  const handleClose = () => {
    setOpen(false)
    formDataReset()
  }

  const createUniversityReview = async (averageRating) => {
    console.log("avgRating", averageRating)
    try {
      await createReview({
        variables: {
          title: title,
          description: message,
          averageRating: averageRating,
          user: userId,
          university: universityDetail.id,
          ratings: {
            academic: academicRate,
            infrastructure: infrastructureRate,
            extracurricular: extracurricularRate,
            accomodation: accomodationRate,
            faculty: facultyRate,
            placement: placementRate,
          },
        },
      }).then(userResponse => {
        console.log("reponse", userResponse.data)
        formDataReset()
        setOpen(false)
      })
    } catch (e) {
      console.log("review post error", e)
    }
  }

  const handleValidation = () => {
    setSaveLoading(true)
    var allAvailable = true
    var averageRating = 0
    console.log("academicRate", academicRate)
    console.log("infrastructureRate", infrastructureRate)
    console.log("extracurricularRate", extracurricularRate)
    console.log("accomodationRate", accomodationRate)
    console.log("facultyRate", facultyRate)
    console.log("placementRate", placementRate)

    if (
      accomodationRate === 0 ||
      infrastructureRate === 0 ||
      extracurricularRate === 0 ||
      accomodationRate === 0 ||
      facultyRate === 0 ||
      placementRate === 0
    ) {
      setRatingError("Please select all the ratings")
      allAvailable = false
    } else {
      setRatingError("")
      var sumOfAllRatings =
        accomodationRate +
        infrastructureRate +
        extracurricularRate +
        accomodationRate +
        facultyRate +
        placementRate
      console.log("sumOfAllRatings", sumOfAllRatings)
      var averageRate = Math.round(sumOfAllRatings / 6)
      console.log("averageRate", averageRate)
      averageRating = averageRate
      // setAvgRating(averageRate)
      // setSaveLoading(false)
    }

    if (title === "") {
      setTitleError("Title error")
      allAvailable = false
    }
    if (message === "") {
      setMessageError("Message error")
      allAvailable = false
    }

    if (allAvailable === true) {
      console.log("avg rating", averageRating)
      createUniversityReview(averageRating)
    } else {
      setSaveLoading(false)
    }
  }

  const formDataReset = () => {
    setAcademicRate(0)
    setAccomodationRate(0)
    setAvgRating(0)
    setExtracurricularRate(0)
    setFacultyRate(0)
    setInfrastructureRate(0)
    setMessage("")
    setMessageError("")
    setPlacementRate(0)
    setRatingError("")
    setSaveLoading(false)
    setTitle("")
    setTitleError("")
  }

  React.useEffect(() => {
    console.log("useEffect called")
  }, [])

  return (
    <div>
      <Dialog
        className="login-signup-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="sign-popup write-review-popup">
          <div className="box">
            <div className="signup-popup">
              <div className="site-logo">
                <Link to="/">
                  <img
                    src={popup_logo}
                    alt="Sticky Logo"
                    className="popuplogo"
                  ></img>
                </Link>
                <DialogContentText>Write A Review</DialogContentText>
              </div>
              <div className="login-fields">
                <div className="field c_pass">
                  <div className="review-list">
                    <p style={{ marginBottom: 0 }}>Academic</p>
                    <ReactStars
                      key="academic"
                      count={5}
                      value={academicRate}
                      size={24}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={academicValue => {
                        console.log("academicValue", academicValue)
                        setAcademicRate(academicValue)
                      }}
                    />
                  </div>
                  <div className="review-list">
                    <p style={{ marginBottom: 0, marginTop: 5 }}>
                      Infrastructure
                    </p>
                    <ReactStars
                      key="infrastructure"
                      count={5}
                      value={infrastructureRate}
                      size={24}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={infrastructureValue => {
                        console.log("infrastructureValue", infrastructureValue)
                        setInfrastructureRate(infrastructureValue)
                      }}
                    />
                  </div>
                  <div className="review-list">
                    <p style={{ marginBottom: 0, marginTop: 5 }}>
                      Extracurricular
                    </p>
                    <ReactStars
                      key="extracurricular"
                      count={5}
                      value={extracurricularRate}
                      size={24}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={extracurricularValue => {
                        console.log(
                          "extracurricularValue",
                          extracurricularValue
                        )
                        setExtracurricularRate(extracurricularValue)
                      }}
                    />
                  </div>
                  <div className="review-list">
                    <p style={{ marginBottom: 0, marginTop: 5 }}>
                      Accomodation
                    </p>
                    <ReactStars
                      key="accomodation"
                      count={5}
                      value={accomodationRate}
                      size={24}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={accomodationValue => {
                        console.log("accomodationValue", accomodationValue)
                        setAccomodationRate(accomodationValue)
                      }}
                    />
                  </div>
                  <div className="review-list">
                    <p style={{ marginBottom: 0, marginTop: 5 }}>Faculty</p>
                    <ReactStars
                      key="faculty"
                      count={5}
                      value={facultyRate}
                      size={24}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={facultyValue => {
                        console.log("facultyValue", facultyValue)
                        setFacultyRate(facultyValue)
                      }}
                    />
                  </div>
                  <div className="review-list">
                    <p style={{ marginBottom: 0, marginTop: 10 }}>Placement</p>
                    <ReactStars
                      key="placement"
                      count={5}
                      value={placementRate}
                      size={24}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={placementValue => {
                        console.log("placementValue", placementValue)
                        setPlacementRate(placementValue)
                      }}
                    />
                  </div>
                  <div className="review-list title">
                    <p style={{ marginTop: 10, marginBottom: 0 }}>Title</p>
                    <textarea
                      id="title"
                      name="title"
                      className={
                        titleError !== "" ? "textarea error" : "textarea"
                      }
                      placeholder="A title/subject for review..."
                      onChange={e => {
                        setTitle(e.target.value)
                      }}
                      onFocus={() => setTitleError("")}
                      style={{
                        width: "350px",
                        height: 150,
                        padding: 5,
                        fontSize: 15,
                      }}
                      value={title}
                    />
                    {/* {messageError ? (
                      <span className="error-message">{messageError}</span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="review-list">
                    <p style={{ marginTop: 10, marginBottom: 0 }}>
                      Description
                    </p>
                    <textarea
                      id="description"
                      name="description"
                      className={
                        messageError !== "" ? "textarea error" : "textarea"
                      }
                      placeholder="A brief about what you want to think..."
                      onChange={e => {
                        setMessage(e.target.value)
                      }}
                      onFocus={() => setMessageError("")}
                      style={{
                        width: "350px",
                        height: 150,
                        padding: 5,
                        fontSize: 15,
                      }}
                      value={message}
                    />
                    {/* {messageError ? (
                      <span className="error-message">{messageError}</span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  {userRoleError !== "" ? (
                    <div className="e-message">
                      {/* <img src={error_logo} alt="" /> {userRoleError} */}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="callback-message" style={{ top: "-3px" }}>
                {ratingError !== "" ? (
                  <div className="e-message">
                    <img src={error_logo} alt="" /> {ratingError}
                  </div>
                ) : null}
              </div>
              {/* <div className="submit">
                <button
                  onClick={() => {
                    handleValidation()
                  }}
                >
                  Save
                </button>
              </div> */}
              <div
                className="submit"
                onClick={() => {
                  if (saveLoading === false) {
                    handleValidation()
                  }
                }}
              >
                <button>
                  {saveLoading === false ? (
                    "Save"
                  ) : (
                    <div className={"centered"} style={{ height: "0px" }}>
                      <BounceIndicator size={14} color={"#0055a4"} />
                    </div>
                  )}
                </button>
              </div>

              {/*<TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={e => { setEmail(e.target.value)}}
              />

              <TextField
                autoFocus={false}
                margin="dense"
                id="password"
                label="password"
                type="email"
                fullWidth
                value={pass}
                onChange={e => {
                  setPass(e.target.value)
                }}
              />

              <TextField
                autoFocus={false}
                margin="dense"
                id="password"
                label="Confirm Password"
                type="password"
                fullWidth
                value={pass}
                onChange={e => {
                  setPass(e.target.value)
                }}
              /> */}
            </div>
          </div>
        </DialogContent>
        {/*<DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions> */}
        <img
          className="closeicon"
          onClick={() => {
            handleClose()
          }}
          src={close_image}
          alt=""
        />
      </Dialog>
    </div>
  )
}
